import {
  ContentState,
  convertToRaw,
  EditorState,
  Modifier,
  RawDraftEntity,
  SelectionState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { CSSProperties } from 'react';
import { Map as iMap } from 'immutable';

export type Mention = {
  name: string; // The display name of the mentioned user
  userId: number; // The user id
};

export const MentionStyles: {
  default: CSSProperties;
  darkMode: CSSProperties;
} = {
  default: {
    backgroundColor: '#D6DEF3',
    color: '#000',
    padding: '2px 8px',
    margin: '2px 1px',
    borderRadius: '50px',
    fontWeight: 500,
    fontSize: '16px',
    whiteSpace: 'nowrap',
  },
  darkMode: {
    backgroundColor: '#7B7891',
    color: '#fff',
    padding: '2px 8px',
    margin: '2px 1px',
    borderRadius: '50px',
    fontWeight: 500,
    fontSize: '16px',
    whiteSpace: 'nowrap',
  },
};

export function replaceMentionsWithFallbackContent(
  contentState: ContentState,
  formattingEnabled = false,
  taggedUsers: Mention[] = []
): {
  text: string;
  mentions: Mention[];
} {
  const mentions: Mention[] = [];
  const rawContent = convertToRaw(contentState);
  let updatedText = '';

  rawContent.blocks.forEach((block) => {
    const blockText = block.text;
    const entityRanges = block.entityRanges;

    // Sort entityRanges to process from start to end
    entityRanges.sort((a, b) => a.offset - b.offset);

    let lastIndex = 0;

    // Helper to handle UTF-16 characters and get substring by code points
    const substringByCodePoints = (text: string, start: number, end: number): string => {
      const codePoints = Array.from(text);
      return codePoints.slice(start, end).join('');
    };

    entityRanges.forEach((entity) => {
      const { key, offset, length } = entity;
      const entityKey = rawContent.entityMap[key];

      if (entityKey && entityKey.type === 'MENTION') {
        const mentionText = entityKey.data?.user?.name ?? '';

        // Append text before mention, handling emojis safely
        updatedText += substringByCodePoints(blockText, lastIndex, offset);

        // Replace mention with @(mentionText)
        updatedText += `@(${mentionText.replace('@', '')})`;

        if (entityKey.data?.user) {
          // Add mention details to the mentions array
          const { access, ...fields } = entityKey.data.user;
          mentions.push(fields);
        } else {
          // htmlToDraft is overwriting the submitted custom entity map,
          // so we are going to cheat and look for the mention in the tagged users
          // received on initialization
          if (formattingEnabled && entityKey.data?.text) {
            const mention = taggedUsers.find(
              (m) => m.name === entityKey.data?.text.replace('@', '')
            );
            if (mention) {
              const { name, userId } = mention;
              mentions.push({ name, userId });
            }
          }
        }

        lastIndex = offset + length;
      }
    });

    // Append the rest of the block text after the last entity, handling emojis safely
    updatedText += substringByCodePoints(blockText, lastIndex, blockText.length);

    // Add a newline if there are multiple blocks
    updatedText += '\n';
  });

  if (formattingEnabled) {
    const rawHtml = draftToHtml(rawContent);
    return { text: rawHtml.trim(), mentions };
  }

  return { text: updatedText.trim(), mentions };
}

// Ensures that the cursor doesn't land inside the mention
export function preventCursorInMention(state: EditorState): EditorState {
  const selection = state.getSelection();
  const contentState = state.getCurrentContent();

  if (!selection.isCollapsed()) {
    return state; // Allow range selections
  }

  const blockKey = selection.getStartKey();
  const block = contentState.getBlockForKey(blockKey);
  const offset = selection.getStartOffset();

  const entityKey = block.getEntityAt(offset);
  if (entityKey) {
    const entity = contentState.getEntity(entityKey);
    if (entity.getType() === 'MENTION') {
      const characterList = block.getCharacterList();

      // Find the start and end of the mention
      const mentionStart = characterList.findIndex((char) => char?.getEntity() === entityKey);
      const mentionEnd = characterList.findLastIndex((char) => char?.getEntity() === entityKey) + 1;

      if (mentionStart === -1 || mentionEnd === -1) {
        return state; // Entity not properly found, fail gracefully
      }

      if (offset >= mentionStart && offset < mentionEnd) {
        // Move the cursor outside the mention
        const newSelection = selection.merge({
          anchorOffset: mentionEnd,
          focusOffset: mentionEnd,
        });

        return EditorState.forceSelection(state, newSelection);
      }
    }
  }

  return state;
}

export const handleRichTextWithUserMentionState = (
  user: Mention,
  editorState: EditorState,
  searchQuery: string
): EditorState => {
  let contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();

  // Get the current block and its text
  const blockKey = selectionState.getAnchorKey();
  const block = contentState.getBlockForKey(blockKey);
  const blockText = block.getText();

  // If no searchQuery, directly insert the mention at the cursor position
  if (!searchQuery) {
    // Determine if the character before the cursor is an '@'
    const anchorOffset = selectionState.getAnchorOffset();
    if (anchorOffset > 0 && blockText[anchorOffset - 1] === '@') {
      // Create a selection state for the '@' character
      const atSymbolSelection = selectionState.merge({
        anchorOffset: anchorOffset - 1,
        focusOffset: anchorOffset,
      }) as SelectionState;

      // Remove the '@' character
      contentState = Modifier.removeRange(contentState, atSymbolSelection, 'backward');
    }

    // Create a mention entity and insert the mention text
    contentState = contentState.createEntity('MENTION', 'IMMUTABLE', { user });
    const entityKey = contentState.getLastCreatedEntityKey();

    // Insert the mention text
    contentState = Modifier.insertText(
      contentState,
      contentState.getSelectionAfter(),
      `@${user.name}`,
      undefined,
      entityKey
    );

    // Add a space after the mention for better readability
    const selectionAfterMention = contentState.getSelectionAfter();
    contentState = Modifier.insertText(contentState, selectionAfterMention, ' ');

    // Push the updated content state and ensure the cursor is placed correctly
    const newEditorState = EditorState.push(editorState, contentState, 'insert-characters');
    return EditorState.forceSelection(newEditorState, contentState.getSelectionAfter());
  }

  // Otherwise, process as usual (searchQuery and @ are present)
  const rawStartOffset = selectionState.getAnchorOffset() - searchQuery.length - 1;
  const rawEndOffset = selectionState.getAnchorOffset();

  const targetSubstring = blockText.slice(rawStartOffset, rawEndOffset);

  if (!targetSubstring.startsWith('@')) {
    return editorState;
  }

  const mentionSelection = selectionState.merge({
    anchorOffset: rawStartOffset,
    focusOffset: rawEndOffset,
  }) as SelectionState;

  contentState = Modifier.removeRange(contentState, mentionSelection, 'backward');
  contentState = contentState.createEntity('MENTION', 'IMMUTABLE', { user });
  const entityKey = contentState.getLastCreatedEntityKey();

  contentState = Modifier.insertText(
    contentState,
    contentState.getSelectionAfter(),
    `@${user.name}`,
    undefined,
    entityKey
  );

  contentState = Modifier.insertText(contentState, contentState.getSelectionAfter(), ' ');

  const newEditorState = EditorState.push(editorState, contentState, 'insert-characters');
  return EditorState.forceSelection(newEditorState, contentState.getSelectionAfter());
};

/**
 * Builds an EditorState from plain text with @(username) mentions and a list of mentions metadata.
 * @param plainText The plain text with mentions formatted as @(username).
 * @param mentions Array of mention objects containing `name` and `userId`.
 * @returns EditorState with mentions correctly added as entities.
 */
export const buildEditorStateFromText = (plainText: string, mentions: Mention[]): EditorState => {
  // Create initial ContentState from plain text
  let contentState = ContentState.createFromText(plainText);

  // Split text into blocks for handling newlines
  const blocks = contentState.getBlocksAsArray();

  // Process mentions for each block
  blocks.forEach((block) => {
    let blockText = block.getText();
    const blockKey = block.getKey();

    mentions.forEach((mention) => {
      const mentionPattern = `@(${mention.name})`;
      let startIndex = blockText.indexOf(mentionPattern);

      while (startIndex !== -1) {
        const endIndex = startIndex + mentionPattern.length;

        // Create a new entity for the mention
        contentState = contentState.createEntity('MENTION', 'IMMUTABLE', {
          user: { userId: mention.userId, name: mention.name },
        });
        const entityKey = contentState.getLastCreatedEntityKey();

        // Replace @(username) with the mention entity
        const selection = SelectionState.createEmpty(blockKey).merge({
          anchorOffset: startIndex,
          focusOffset: endIndex,
        });

        contentState = Modifier.replaceText(
          contentState,
          selection,
          `@${mention.name}`, // Replace mention pattern with mention name
          undefined,
          entityKey
        );

        // Update the block text for subsequent matches
        blockText = contentState.getBlockForKey(blockKey).getText();

        // Find the next occurrence of @(username) in the block
        startIndex = blockText.indexOf(mentionPattern, startIndex + `@${mention.name}`.length);
      }
    });
  });

  // Create EditorState with updated ContentState
  return EditorState.createWithContent(contentState);
};

export const buildEditorStateFromHtml = (htmlText: string, mentions: Mention[]): ContentState => {
  const contentBlock = htmlToDraft(htmlText);

  if (contentBlock) {
    // Add user property to entityMap based on mentions
    const mentionMap = new Map(mentions.map((m) => [m.name, m]));

    const { contentBlocks, entityMap } = contentBlock;

    // Convert Immutable entityMap to a plain JavaScript object
    const entityMapJS = entityMap.toJS ? entityMap.toJS() : entityMap;

    const updatedEntityMap = Object.entries(entityMapJS).reduce((acc, [key, entity]) => {
      const typedEntity = entity as RawDraftEntity; // Explicitly type entity
      const mention = mentionMap.get(typedEntity.data?.text?.replace('@', '')); // Adjust based on your mention key
      if (mention) {
        acc[key] = {
          ...typedEntity,
          data: {
            ...typedEntity.data,
            user: { userId: mention.userId, name: mention.name },
          },
        };
      } else {
        acc[key] = typedEntity;
      }
      return acc;
    }, {} as Record<string, RawDraftEntity>);

    // Convert updatedEntityMap back to Immutable Map
    const immutableEntityMap = iMap(updatedEntityMap);

    return ContentState.createFromBlockArray(contentBlocks, immutableEntityMap);
  }

  return ContentState.createFromText('');
};

export const handleRemoveAtSignBeforeCursor = (
  editorState: EditorState,
  searchQuery: string
): { state: EditorState; content: ContentState } | undefined => {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  if (!selection.isCollapsed()) {
    return; // Don't process if there's a text selection
  }

  const anchorKey = selection.getAnchorKey();
  const anchorOffset = selection.getAnchorOffset();
  const block = contentState.getBlockForKey(anchorKey);

  const text = block.getText();

  // Calculate the index of the potential "@" sign before the search query.
  const atSignIndex = anchorOffset - searchQuery.length - 1;

  // Check if the character before the cursor and/or search query is an "@" sign
  if (anchorOffset > 0 && text[atSignIndex] === '@') {
    // If an entity is attached to the "@" character, check its type.
    const entityKey = block.getEntityAt(atSignIndex);
    if (entityKey) {
      const entity = contentState.getEntity(entityKey);
      if (entity.getType() === 'MENTION') {
        // The "@" is part of a mention, so don't remove it.
        return;
      }
    }

    const newSelection = SelectionState.createEmpty(anchorKey).merge({
      anchorOffset: anchorOffset - searchQuery.length - 1,
      focusOffset: anchorOffset,
    });

    const newContentState = Modifier.removeRange(contentState, newSelection, 'backward');
    const finalEditorState = EditorState.push(editorState, newContentState, 'remove-range');
    return { state: finalEditorState, content: newContentState };
  }

  return undefined;
};
