import React from 'react';
import { Autocomplete, TextField, createFilterOptions } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import styled from 'styled-components';
import { FieldLabel } from 'components/Form/FormField';
import { DropdownInterface, DropdownOption } from 'components/DropdownSelect/dropdown.types';
import chevronDown from 'assets/icons/chevronDown.svg';
import { useTheme } from '@mui/material/styles';
import { RequiredAsterisk } from 'components/RequiredeAsterisk/RequiredAsterisk';

const AutocompleteContainer = styled.div<{ $containedLabel?: boolean; $marginTop?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${(props) => props.$containedLabel && 'margin-top: 1rem'}
  ${(props) => props.$marginTop && `margin-top: ${props.$marginTop};`}
`;

export type SingleSelectDropdownProps = DropdownInterface<DropdownOption, undefined>;

const SingleSelectDropdown = ({
  fieldLabel,
  allOptions,
  selected,
  setSelected,
  fullWidth,
  sx,
  placeholder,
  variant,
  disabled,
  defaultOption,
  containedLabel,
  disableClearable,
  marginTop,
  inputProps,
  required,
  onNewOption,
  customVariant,
  'data-testid': dataTestId,
  chevronDownIconProps,
  clearIconProps,
}: SingleSelectDropdownProps): JSX.Element => {
  const theme = useTheme();
  const filter = createFilterOptions<DropdownOption>();
  const [handleNewOption, typeName] = onNewOption ?? [];
  let counter = allOptions.length;

  const hasGroupBy = allOptions.some((option) => option.groupByValue);

  return (
    <AutocompleteContainer $containedLabel={containedLabel} $marginTop={marginTop}>
      {!containedLabel && (
        <FieldLabel>
          {fieldLabel} {required && <RequiredAsterisk />}
        </FieldLabel>
      )}
      <Autocomplete
        fullWidth={fullWidth}
        id={`${fieldLabel}-single-select-dropdown`}
        options={allOptions}
        defaultValue={defaultOption ?? null}
        getOptionLabel={(option) => option.inputValue ?? option.name}
        value={selected || null}
        readOnly={disabled}
        disabled={disabled}
        onChange={async (_event: React.SyntheticEvent, newValue: DropdownOption | null) => {
          if (newValue?.inputValue && handleNewOption) {
            const id = await handleNewOption(newValue.inputValue);
            if (id) {
              setSelected({ id, name: newValue.inputValue });
            }
          } else {
            setSelected(newValue ?? undefined);
          }
        }}
        filterOptions={(options, params): DropdownOption[] => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some((option) => inputValue === option.name);
          if (inputValue !== '' && !isExisting && onNewOption) {
            filtered.push({
              id: ++counter,
              inputValue,
              name: `+ Add ${typeName} "${inputValue}"`,
            });
          }
          return filtered;
        }}
        className={customVariant}
        renderInput={(params) => (
          <TextField
            {...params}
            {...inputProps}
            disabled={disabled}
            variant={variant ?? 'outlined'}
            placeholder={placeholder}
            className={customVariant}
            label={containedLabel && fieldLabel}
            sx={
              disabled
                ? { backgroundColor: theme.colors.offWhite, ...inputProps?.sx }
                : inputProps?.sx
            }
            inputProps={{
              ...params.inputProps,
              'data-testid': dataTestId,
            }}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          );
        }}
        isOptionEqualToValue={(option, selected) => option?.id === selected?.id}
        sx={sx}
        disableClearable={disableClearable}
        popupIcon={
          !disabled ? <img src={chevronDown} alt='Chevron Down' {...chevronDownIconProps} /> : ''
        }
        clearIcon={!disableClearable ? <ClearIcon {...clearIconProps} /> : ''}
        groupBy={hasGroupBy ? (option) => option.groupByValue ?? 'Misc' : undefined}
      />
    </AutocompleteContainer>
  );
};

export default SingleSelectDropdown;
