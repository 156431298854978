import { gql } from '@apollo/client';

export const GET_CAMPAIGN = gql`
  query getCampaign($id: Int!) {
    campaign(id: $id) {
      id
      name
      dealName
      startDate
      endDate
      archived
      measurementUnits
      enableDocumentSystem
      pillarScoreDashboard
      reportScoreDashboard
      showReportScores
      currency
      measurementUnits
      tags {
        id
        name
        displayColour
        archived
      }
    }
  }
`;
