import { DateTime, DateTimeOptions } from 'luxon';
import { DateForEnum } from '../__generated__/graphql';
import { ISO_DATE_FORMAT } from './formatting';
import moment from 'moment-timezone'; // TECHDEBT: remove uses of moment and moment-timezone HR20-775

export function getTimezones() {
  const timeZones = moment.tz.names();

  return timeZones
    .filter((tz: string) => tz.includes('/')) // Only include time zones with multiple parts -- anything that is singular is a duplicate
    .filter((tz: string) => {
      const region = tz.split('/')[0];
      return region !== 'Etc' && region !== 'US'; // Exclude "Etc" and "US" regions -- confirmed these are also duplicates
    })
    .filter((tz: string) => tz.split('/').pop()!.length > 3) // Remove items with last segment 3 characters or shorter -- more duplicates...
    .map((tz: string) => {
      const parts = tz.split('/');
      let region = parts[0];
      let name: string;

      if (region === 'America') {
        region = 'Americas'; // Otherwise we get things like America: Mexico City which is obviously not correct
      }

      const formattedCity = parts[1].split('_').join(' ');

      if (parts.length === 2) {
        name = `${region}: ${formattedCity}`;
      } else {
        const additionalDetail = parts.pop()!.split('_').join(' ');
        name = `${region}: ${additionalDetail}`;
      }

      return {
        name,
        value: tz,
      };
    });
}

export const datePickerFormat =
  DateTime.parseFormatForOpts(DateTime.DATE_FULL, { locale: DateTime.local().locale ?? 'en-AU' }) ??
  'MM/dd/yyyy';

export function getDateTime(
  date?: Date | DateTime | string | number,
  opts?: DateTimeOptions
): undefined | DateTime {
  if (!date) {
    return undefined;
  }

  if (date instanceof Date) {
    return DateTime.fromJSDate(date, opts);
  }

  if (date instanceof DateTime) {
    return date;
  }

  if (typeof date === 'string') {
    return DateTime.fromISO(date, opts);
  }

  return DateTime.fromMillis(date, opts);
}

export function convertDateRangeStringToDateStringArray(dateRange: string | undefined) {
  const dates = convertDateRangeStringToDateArray(dateRange);
  return dates.map((date) => date.toISO());
}

export function convertDateRangeStringToDateArray(dateRange: string | undefined) {
  if (!dateRange) {
    return [];
  }

  const today = DateTime.now();
  let startDate = today.startOf('day');
  let endDate = today.endOf('day');

  switch (dateRange) {
    case 'Today':
      break;
    case 'Yesterday':
      startDate = today.minus({ days: 1 }).startOf('day');
      endDate = today.minus({ days: 1 }).endOf('day');
      break;
    case 'This week':
      startDate = today.startOf('week').minus({ days: 1 });
      endDate = today.endOf('week').minus({ days: 1 });
      break;
    case 'Last week': {
      startDate = today.minus({ weeks: 1 }).startOf('week').minus({ days: 1 });
      endDate = today.minus({ weeks: 1 }).endOf('week').minus({ days: 1 });
      break;
    }
    case 'Last 7 days':
      startDate = today.minus({ days: 6 }).startOf('day');
      break;
    case 'Last 14 days':
      startDate = today.minus({ days: 13 }).startOf('day');
      break;
    case 'Last 28 days':
      startDate = today.minus({ days: 27 }).startOf('day');
      break;
    case 'Last 30 days':
      startDate = today.minus({ days: 29 }).startOf('day');
      break;
    case 'This month':
      startDate = today.startOf('month');
      endDate = today.endOf('month');
      break;
    case 'Last month':
      startDate = today.minus({ months: 1 }).startOf('month');
      endDate = today.minus({ months: 1 }).endOf('month');
      break;
    case 'Last 90 days':
      startDate = today.minus({ days: 89 }).startOf('day');
      break;
    case 'Year to date':
      startDate = today.startOf('year');
      break;
    case 'This year':
      startDate = today.startOf('year');
      endDate = today.endOf('year');
      break;
    case 'Last 12 months':
      startDate = today.minus({ months: 11 }).startOf('month');
      break;
    case 'Last calendar year':
      startDate = today.minus({ years: 1 }).startOf('year');
      endDate = today.minus({ years: 1 }).endOf('year');
      break;
    case 'Next 7 days':
      endDate = today.plus({ days: 7 }).endOf('day');
      break;
    case 'Next 14 days':
      endDate = today.plus({ days: 14 }).endOf('day');
      break;
    case 'Next 28 days':
      endDate = today.plus({ days: 28 }).endOf('day');
      break;
    case 'Custom':
      startDate = today.minus({ months: 6 }).startOf('month');
      break;
    default:
      return [];
  }

  return [startDate, endDate];
}

export function convertLabelToDateEnum(label?: string): DateForEnum | undefined {
  switch (label) {
    case 'Last 7 days':
      return DateForEnum.LastSevenDays;
    case 'This month':
      return DateForEnum.ThisMonth;
    case 'This year':
      return DateForEnum.ThisYear;
    case 'Last 30 days':
      return DateForEnum.LastThirtyDays;
    default:
      return;
  }
}

export const toApiFriendlyDateFormat = (dateObject: Date) =>
  DateTime.fromJSDate(dateObject).toFormat(ISO_DATE_FORMAT);

// Display DateStrings
export function displayDateShort(date?: Date | string, placeholder = 'N/A') {
  return displayDateString(date, placeholder, 'D');
}

export function displayDateMedium(date?: Date | string, placeholder = 'N/A') {
  return displayDateString(date, placeholder, 'DD');
}

export function displayDateLong(date?: Date | string, placeholder = 'N/A') {
  return displayDateString(date, placeholder, 'DDD');
}

function displayDateString(date?: Date | string, placeholder = 'N/A', toFormat = 'DD'): string {
  if (!date) {
    return placeholder;
  }

  if (date instanceof Date) {
    date = date.toISOString();
  }

  if (date.length > 10) {
    date = date.slice(0, 10);
  }

  return DateTime.fromFormat(date, ISO_DATE_FORMAT).toFormat(toFormat);
}

// Display DateTimes

type DisplayDateTimeInput = {
  date?: Date | string;
  placeholder?: string;
  timezone?: string | null;
};

export function displayDateTimeShort(input: DisplayDateTimeInput) {
  return displayDateTimeString(input.date, input.placeholder, 'D', input.timezone);
}

export function displayDateTimeMedium(input: DisplayDateTimeInput) {
  return displayDateTimeString(input.date, input.placeholder, 'DD', input.timezone);
}

export function displayDateTimeLong(input: DisplayDateTimeInput) {
  return displayDateTimeString(input.date, input.placeholder, 'DDD', input.timezone);
}

export function displayDateTimeLongWithDay(input: DisplayDateTimeInput) {
  return displayDateTimeString(input.date, input.placeholder, 'EEEE DDD', input.timezone);
}

export function displayDateTimeShortFullTime(input: DisplayDateTimeInput) {
  return displayDateTimeString(input.date, input.placeholder, 'f ZZZZ', input.timezone);
}

export function displayDateTimeShortFullTimeUTC(input: DisplayDateTimeInput) {
  return displayDateTimeString(input.date, input.placeholder, 'f', input.timezone);
}

export function displayDateTimeMediumFullTime(input: DisplayDateTimeInput) {
  return displayDateTimeString(input.date, input.placeholder, 'ff ZZZZ', input.timezone);
}

export function displayDateTimeLongFullTime(input: DisplayDateTimeInput) {
  return displayDateTimeString(input.date, input.placeholder, 'fff', input.timezone);
}

export function displayDateTimeLongFullTimeUTC(input: DisplayDateTimeInput) {
  return displayDateTimeString(input.date, input.placeholder, 'DDD h:mm a', input.timezone);
}

export function displayDateTimeLongWithDayAndAtFullTimeUTC(input: DisplayDateTimeInput) {
  return displayDateTimeString(input.date, input.placeholder, "EEE DD 'at' h:mm a", input.timezone);
}

export function displayDateTimeLongFullTimeUTCWithTimezone(input: DisplayDateTimeInput) {
  return displayDateTimeString(input.date, input.placeholder, 'DDD h:mm a (ZZZZ)', input.timezone);
}

export function displayDateTimeLongFullTimeUTCWithAt(input: DisplayDateTimeInput) {
  if (input.date) {
    return displayDateTimeString(
      input.date,
      input.placeholder,
      "DDD 'at' h:mm a (ZZZZ)",
      input.timezone
    );
  }

  return input.placeholder;
}

export function displayTimeWithoutDateUTC(input: DisplayDateTimeInput) {
  return displayDateTimeString(input.date, input.placeholder, 'h:mm a', input.timezone);
}

function displayDateTimeString(
  date?: Date | string,
  placeholder = 'N/A',
  toFormat = 'DD',
  timezone?: string | null
): string {
  if (date instanceof Date) {
    const dt = DateTime.fromJSDate(date);
    return timezone ? dt.setZone(timezone).toFormat(toFormat) : dt.toFormat(toFormat);
  } else if (typeof date === 'string') {
    const dt = DateTime.fromISO(date);
    return timezone ? dt.setZone(timezone).toFormat(toFormat) : dt.toFormat(toFormat);
  }

  return placeholder;
}
